import ApiAxiosService from "@/core/services/ApiAxiosService";
import {handleError} from "@/core/helpers/response";
import {APIs} from "@/store/enums/ApiEnums";


export const getCities = async (governorateIds) => {
    try {
        let response = await ApiAxiosService.get(APIs.GOVERNORATE.cities(governorateIds) ,{});
        return response.data.data.cities;
    }catch (e) {
        handleError(e)
    }
}

export const getGovernorates = async (countryId) => {
    try {
        let response = await ApiAxiosService.get(APIs.COUNTRY.governorates(countryId) ,{});
        return response.data.data.governorates;
    }catch (e) {
        handleError(e)
    }
}
export const getServices = async (search) => {
    try {
        let response = await ApiAxiosService.get(APIs.SERVICE.list ,{search : search});
        return response.data.data.services;
    }catch (e) {
        handleError(e)
    }
}

export const getUsers = async (search) => {
    try {
        let response = await ApiAxiosService.get(APIs.USER.list ,{search : search});
        return response.data.data.users;
    }catch (e) {
        handleError(e)
    }
}

export const getProviders = async (search) => {
    try {
        let response = await ApiAxiosService.get(APIs.PROVIDER.list ,{search : search});
        return response.data.data.providers;
    }catch (e) {
        handleError(e)
    }
}